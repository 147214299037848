import React from "react"
import Layout from "../components/layout"
import { Container, Section } from "../components/ui"

export default function Careers() {
  return (
    <Layout style={{ height: "100%" }}>
      <Section style={{ height: "100%" }}>
        <Container style={{ height: "100%" }}>
          <h1>Careers</h1>
          <p>
            <strong>
              We hire based on our six values and on your previous track record
              of delivering impact. Do you recognize yourself in this?{" "}
            </strong>
          </p>
          <ul>
            <li>
              <p>
                We’re looking for passion, flexibility, strong communication,
                and a commitment to always improving your skills. These are the
                basic qualities needed to succeed as an early employee.
              </p>
            </li>
            <li>
              Our values resonate with you and you can easily think of past
              experiences that demonstrate these values:{" "}
            </li>
            <ul>
              <li>Customer obsessed</li>
              <li>Long-term focused</li>
              <li>Data informed</li>
              <li>Committed to building trust and a safe space</li>
              <li>Committed to expand your horizon</li>
              <li>Committed to simplifying things</li>
            </ul>
          </ul>
          <br />
          <p>
            We're always looking for new talents at iwn studio, if you feel you
            can be a great fit please reach out at{" "}
            <a href="mailto:info@iwn.studio">jobs@iwn.studio</a>.
          </p>
          <p>
            <strong>
              To see currently open positions{" "}
              <a
                target="_blank"
                href="https://www.linkedin.com/company/iwnstudio/?viewAsMember=true"
              >
                click here
              </a>
              .
            </strong>
          </p>
          <div></div>
        </Container>
      </Section>
    </Layout>
  )
}
